* {
  box-sizing: border-box;
}

html,
body,
#root {
  margin: 0;
  height: 100%;
  min-height: 100%;
}

html {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
